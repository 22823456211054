.navbar{
    position: absolute;
    display: flex;
    align-items: center;
    background: white;
    height: 9vw;
    width: 100%;
    z-index: 9001;
    position: fixed;
    border-bottom: black 2px solid;
}

.navbar-logo{
    position: relative;
    display: flex;
    align-items: center;
    height: 10vw;
    width: 20vw;
}

.navbar img{
    width: 25vw;
    height: auto;
    /*
    284px
    92px
    */

    filter: invert(1);
}

span{
    display: inline-block;
    margin-right: 0px;
    font-family: 'Patua One', cursive;
    text-align: center;
}

.not-logo-nav-items{
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    margin-right: 0vw;
    margin-left: 4vw;
}

a{
    text-decoration: none;
    color: black;
    margin: 2vw;
    font-size: 1.4vw;
}

.not-logo-nav-items span:hover{
    display: inline-block;
    border-bottom: 0.5vw solid transparent;
}