.construction-page{
    background-color: white;
    font-family: 'IBM Plex Serif', serif;
    text-align: center;
}

.construction-page h1{
    color: black;
    font-size: 3vw;
    font-weight: 900;
    font-family: 'IBM Plex Serif', serif;
}

.construction-page h2{
    padding-top: 2vw;
    color: black;
    font-size: 2.75vw;
    font-weight: 900;
    font-family: 'IBM Plex Serif', serif;
}

.construction-page span{
    width: 80vw;
    color: black;
    font-size: 1.25vw;
    font-weight: 700;
    font-family: 'IBM Plex Serif', serif;
}

.construction-spacer{
    height: 9vw;
}

iframe{
    width: 64vw;
    height: 36vw;
    box-shadow: 0 0 100px rgba(45, 0, 71, 0.64);
}

h3{
    font-size: 2vw;
}