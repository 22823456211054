.contact-page{
    position: relative;
    top: 0;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.contact-spacer{
    width: 100%;
    height: 9vw;
}

.form input{
    height: 1.5vw;
}

.dark-arts{
    margin-top: 1.5vw;
    width: 100%;
    display: flex;
    justify-content: center;
}

.dark-arts span{
    font-size: 1.5vw;
    width: 7.5vw;
    margin-right: 3vw;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 900;
}

.name-input{
    width: 28.4vw;
    font-size: 1vw;
    background-color: rgb(55, 0, 77);
    opacity: 0.75;
}

.email-input{
    width: 28.4vw;
    font-size: 1vw;
    background-color: rgb(55, 0, 77);
    opacity: 0.75;
}

.contact-text{
    color: black;
    align-items: center;
    justify-content: center;
}

.contact-text h1{
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'IBM Plex Serif', serif;
    font-size: 4.4vw;

    margin: 0;
    padding: 0;
}

.contact-text h2{
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'IBM Plex Serif', serif;
    font-size: 2vw;

    margin: 0;
    padding: 0;
}

.phone{
    margin-right: 18.6vw;
    width: 10vw;
    font-size: 1vw;
    text-align: center;
    background-color: rgb(55, 0, 77);
    opacity: 0.75;
}

.textarea-control{
    font-size: 1vw;
}

.phone-most-right{
    text-align: center;
    width: 3.2vw;
    font-size: 1vw;
    background-color: rgb(55, 0, 77);
    opacity: 0.75;
}

.form-group-one{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40%;
    height: 20vw;
}

.form-group-two{
    margin-left: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40%;
    height: 20vw;
}

.sub-two{
    display: flex;
    position: relative;
    align-items: center;
    float: left;
    width: 100%
}

.form textarea{
    position: relative;
    resize: none;
    height: 10vw;
    width: 30vw;
    background-color: rgb(55, 0, 77);
    opacity: 0.75;
}

.almost-all{
    width: 100%;
    display: flex;
    justify-content: center;
}

.form{
    position: relative;
    height: 32.5vw;
    margin-top: 1.5vw;
}

.submit-arts{
    display: flex;
    justify-content: center;
    height: 5vw;
}

.form .submit-button{
    width: 10vw;
    height: 4vw;
    background-color:rgb(55, 0, 77);
    border: none;
    padding-bottom: 0.5vw;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 900;
    font-size: 1.5vw;
    color: white;
}

.form .submit-button:hover{
    width: 10vw;
    height: 4vw;
    background-color:rgb(55, 0, 77);
    border: none;
    padding-bottom: 0.5vw;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 900;
    font-size: 1.5vw;
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

.form .submit-button:active{
    transform: translateY(-0.25vw);

    width: 10vw;
    height: 4vw;
    background-color:rgb(55, 0, 77);
    border: none;
    padding-bottom: 0.5vw;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 900;
    font-size: 1.5vw;
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

input{
    color: white;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    -webkit-appearance: none;
    border-radius: 0vw;
}

textarea{
    color: white;
    font-family: 'IBM Plex Serif', serif;
    border-radius: 0vw;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
