.realty-page{
    background-color: white;
    font-family: 'IBM Plex Serif', serif;
    text-align: center;
}

.realty-page h1{
    color: black;
    font-size: 3.3vw;
    font-weight: 900;
    font-family: 'IBM Plex Serif', serif;
}

.realty-page h2{
    padding-top: 2vw;
    color: black;
    font-size: 2.5vw;
    font-weight: 900;
    font-family: 'IBM Plex Serif', serif;
}

.realty-page span{
    color: black;
    font-size: 1.5vw;
    font-weight: 700;
    font-family: 'IBM Plex Serif', serif;
}

.realty-spacer{
    height: 9vw;
}

iframe{
    width: 64vw;
    height: 36vw;
    box-shadow: 0 0 100px rgba(45, 0, 71, 0.64);
}