.about-about-page{
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: -moz-hidden-unscrollable;
    background-color: black;
    font-family: 'IBM Plex Serif', serif;
}

.oops{
    transform: scaleX(-1);
    opacity: 0.75;
    object-fit: cover;
    width: 100%;
    height: 110vh;
    min-height: 75vw;
}

.about-spacer{
    width: 100%;
    height: 9vw;
}

.about-about-container{
    width: 100%;
    top: 17.5vw;
    position: absolute;
    height: 37.5vw;
    z-index: 5;
    display: flex;
    /*
    border: solid green 0.2vw;
    */
}

.about-seller-two{
    position: absolute;
    top: 7.5vw;
    /*
    top: 22.5rem;
    21.5%
    360px
    */
    width: 100%;
    text-align: center;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.about-seller-two h2{
    color:indigo;
    font-size: 0vw;
}

.about-seller-two h1{
    font-size: 4vw;
    font-family: 'IBM Plex Serif', serif;
    color: white;
}

.about-sub-seller-two{
    width: 70%;
    margin-left: 15%;
}

.about-about-page p{
    margin-left: 2vw;
    margin-right: 2vw;
    padding-top: 0.5vw;

    font-family: 'IBM Plex Serif', serif;
    font-size: 1.3vw;
    color: white;
}

.about-about-page p a{
    text-decoration: none;
    text-decoration: underline;
    font-family: 'IBM Plex Serif', serif;
    font-size: inherit;
    color: white;
    margin: 0;
    padding: 0;
}

.about-about-container img{
    margin-left: 5vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    border-radius: 3vw;
    border: solid #2c0041 0.5vw;
    background-color: #2c0041;
    width: 21.7vw;
    height: 1.5*21.7vw;
}