.home-page{
    position: relative;
    top: 0;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: black;
    overflow: visible;
}

.home-page ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.home-page h1{
    font-family: 'IBM Plex Serif', serif;
    font-size: 3vw;
    /*
    4rem
    */
    color: white;
    margin: 0;
    padding: 0;
}

.home-page h2{
    font-family: 'IBM Plex Serif', serif;
    font-size: 1.5vw;
    /*
    2rem
    */
    color: white;
    margin: 0;
    padding: 0;
}

.seller-one{
    position: absolute;
    top: 10vw;
    /*
    top: 22.5rem;
    21.5%
    360px
    */
    width: 100%;
    text-align: center;
    z-index: 2;
}

.sub-seller-one{
    width: 70%;
    margin-left: 15%;
    padding-top: 3vw;
    padding-bottom: 3vw;
    /*
    border: solid white 0.1vw;
    */
}

.ding-dong-help-div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.realty-button{
    display: flex;
    position: absolute;
    background-color: #2c0041;
    top: 87vh;
    text-align: center;
    z-index: 2;
    width: 20vw;
    justify-content: center;
    border-radius: 5vw;
    border: solid white 0.1vw;
    padding-bottom: 0.5vw;
}

.realty-button h2{
    font-size: 2.75vw;
}

.realty-button:hover{
    display: flex;
    position: absolute;
    top: 87vh;
    text-align: center;
    z-index: 2;
    width: 20vw;
    justify-content: center;
    border-radius: 5vw;
    border: solid white 0.1vw;
    padding-bottom: 0.5vw;
    background-color: #6b00a8;
}

.other-buttons-help{
    width: 100%;
    display: flex;
    justify-content: center;
}

.other-buttons{
    display: flex;
    align-items: center;
    position: absolute;
    top: 100vh;
    text-align: center;
    z-index: 2;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
}

.other-buttons h2{
    font-size: 1.5vw;;
}

.other-buttons li{
    background-color: #2c0041;
    font-family: 'Roboto', sans-serif;
    border-radius: 5vw;
    border: solid white 0.1vw;
    padding-top: 0.2vw;
    padding-bottom: 0.5vw;
    padding-right: 2vw;
    padding-left: 2vw;
}

.other-buttons li:hover{
    font-size: 3.5vw;
    color: white;
    font-family: 'Roboto', sans-serif;
    border: solid white 0.1vw;
    padding-top: 0.2vw;
    padding-bottom: 0.5vw;
    padding-right: 2vw;
    padding-left: 2vw;
    background-color: #6b00a8;
}

.seller-two{
    position: absolute;
    top: 67.5vw;
    /*
    top: 22.5rem;
    21.5%
    360px
    */
    width: 100%;
    text-align: center;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.seller-two h2{
    color:indigo;
    font-size: 0vw;
}

.seller-two h1{
    font-size: 4vw;
}

.sub-seller-two{
    width: 70%;
    margin-left: 15%;
}

.seller-three{
    position: absolute;
    top: 110.9vw;
    /*
    top: 22.5rem;
    21.5%
    360px
    */
    width: auto;
    text-align: center;
    z-index: 2;
    /*
    background-color: red;
    */
    display: flex;
    justify-content: center;
    margin-left: 3vw;
}

.seller-three h2{
    font-family: 'IBM Plex Serif', serif;
    font-size: 2.5vw;
    /*
    2rem
    */
    color: white;
    margin: 0;
    padding: 0;
}

.sub-seller-three{
    /*
    top: 22.5rem;
    21.5%
    360px
    */
    text-align: center;
    z-index: 2;
    border: solid white 0.1vw;
    padding-top: 0.5vw;
    padding-bottom: 1vw;
    margin: 0;
    border-radius: 5vw;
    background-color: #2c0041;

    padding-left: 2vw;
    padding-right: 2vw;
}

.sub-seller-three:hover{
    text-align: center;
    z-index: 2;
    padding-top: 0.5vw;
    padding-bottom: 1vw;
    margin: 0;
    border-radius: 5vw;
    background-color: #6b00a8;
    padding-left: 2vw;
    padding-right: 2vw;
}

.about-container{
    width: 100%;
    top: 75vw;
    position: absolute;
    height: 37.5vw;
    z-index: 5;
    display: flex;
    /*
    border: solid green 0.2vw;
    */
}

.about-container img{
    margin-left: 5vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    border-radius: 3vw;
    border: solid #2c0041 0.5vw;

    width: 21.7vw;
    height: 1.5*21.7vw;
}

.about-container p{
    margin-left: 2vw;
    margin-right: 2vw;
    padding-top: 0.5vw;

    font-family: 'IBM Plex Serif', serif;
    font-size: 1.3vw;
    color: white;
}

.about-container p a{
    text-decoration: none;
    font-family: 'IBM Plex Serif', serif;
    font-size: inherit;
    color: white;
    margin: 0;
    padding: 0;
}

.spacer{
    position: relative;
    height: 9vw;
    max-width: 100%;
    background-color: white;
}

.contentOne{
    margin: 0;
    height: 15vw;
    display: flex;
    justify-content: center;
    background: white;
}

.contentOne h2{
    padding-top: 1vw;
    color: black;
}

video{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    filter: contrast(100%);
    border-top: solid #2c0041 0.1vw;
}