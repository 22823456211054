.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 3vw;
    background-color: #110022;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .footer-contents li{
      list-style: none;
  }

  .footer-again{
      display: flex;
      align-items: center;
  }

  .footer-again span{
      width: 100%;
      position: absolute;
      text-align: center;
      font-family: 'IBM Plex Serif', serif;
      font-size: 1vw;
      color: rgba(255, 255, 255, 0.75);
  }

  .footer-logo{
      height: 2.75vw;
      padding-right: 20vw;
      padding-left: 2vw;
  }